$contentMargin: 50px;
$contentPadding: 30px;


.my-article-class {
    margin: 26px;
}


body {
    .homepage-content {
        margin-left: auto;
        margin-right: auto;
        padding: $contentPadding;
        max-width: 44em;
    }

    div.work-image-div {
        margin: 5px;
        padding: 5px 5px 5px 30px;
        height: auto;
        display: inline-block; 
        
        img {
            height: auto;
            max-width: 480px;
        }
    }

    div.work-content-div a {
        width: 135 !important;
        height: 40 !important;
        display:inline-block;
        overflow:hidden;
    }
}
